import React, { useState, useEffect } from 'react';
import '../src/components/payment.css';
import { contractStaking } from '../src/config';
import TwitterAbi from '../src/abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Leaderboard from './pages/leaderboard';
import Referrer from './pages/referrer';
import Deposit from './pages/deposit';
import Airdrop from './pages/airdrop';
import { Link } from 'react-router-dom';

const App = () => {

  return (
    <>

      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/referrer' element={<Referrer />} />
        <Route path='/deposit' element={<Deposit />} />
        <Route path='/airdrop' element={<Airdrop />} />


      </Routes>

    </>
  );
};

export default App;
