import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import logo from '../images/logo.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import wallet from '../images/shareholder.png';
import nft from '../images/sponsorship.png';
import sell from '../images/rank.png';


const Process = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="processMain" data-aos="zoom-in" data-aos-duration="500">

                <div className='airdropH'>COMMUNITY AIRDROP</div>

                <div class="worksMain" data-aos="fade" data-aos-duration="500">

                    <div class="worksMain2">
                        <div class="worksMain2Div">Step 1</div>
                        <div class="worksMain2DivP">Enter an invite code to claim your airdrop. You can obtain invite codes by joining our Discord or checking our X/Twitter account.</div>
                    </div>

                    <div class="worksMain2">
                        <div class="worksMain2Div">Step 2</div>
                        <div class="worksMain2DivP">Earn Yield + Beast Points by bridging assets to Beast. Your Points will increase gradually as you bridge more assets over time.</div>
                    </div>

                    <div class="worksMain2">
                        <div class="worksMain2Div">Step 3</div>
                        <div class="worksMain2DivP">Share your referral link with your friends and earn 20% referral bonus points.</div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Process;
