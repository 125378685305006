import React from 'react';

const PulsingMovingDotsSVG = () => {
  const animatedDotProps = {
    fill: '#5ee211',
    r: '2',
    opacity: '0.9',
    filter: 'url(#pulsingFilter)',
  };

  const pulsingFilterProps = {
    id: 'pulsingFilter',
    x: '0',
    y: '0',
    width: '150%',
    height: '150%',
  };

  const commonAnimationProps = {
    attributeName: 'r',
    values: '2;4;2',
    repeatCount: 'indefinite',
    dur: '2s',
  };

  const movingAnimationProps = {
    attributeName: 'cy',
    values: '0;100%',
    repeatCount: 'indefinite',
    dur: '2s',
  };

  return (
    <svg
      id="full-width-svg"
      className="background-svg"
      height="100%"
      width="100%"
      style={{ position: 'fixed', zIndex: '0' }}
    >
      <defs>
        <filter {...pulsingFilterProps}>
          <feComponentTransfer in="SourceGraphic">
            <feFuncA type="table" tableValues="0 0.1 0.3 0.5 0.7 0.5 0.3 0.1 0" />
          </feComponentTransfer>
        </filter>
      </defs>

      <circle cx="30%" cy="0" {...animatedDotProps} >

        {/* Move to x=10% and y=100vh after reaching 50vh */}

        <animate
          attributeName="cx"
          values="30%;30%"
          keyTimes="0;1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="cy"
          values="0;50vh"
          keyTimes="0;1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
        />

      </circle>

      <circle cx="30%" cy="0" {...animatedDotProps} >

        {/* Move to x=10% and y=100vh after reaching 50vh */}

        <animate
          attributeName="cx"
          values="30%;10%"
          keyTimes="0;1"
          dur="2s"
          begin="2s"
          repeatCount="indefinite"

        />
        <animate
          attributeName="cy"
          values="50vh;100vh"
          keyTimes="0;1"
          dur="2s"
          begin="2s"
          repeatCount="indefinite"

        />
      </circle>

      <circle cx="70%" cy="0" {...animatedDotProps} >

        {/* Move to x=10% and y=100vh after reaching 50vh */}

        <animate
          attributeName="cx"
          values="70%;70%"
          keyTimes="0;1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="cy"
          values="0;50vh"
          keyTimes="0;1"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
        />

      </circle>

      <circle cx="70%" cy="0" {...animatedDotProps} >

        {/* Move to x=10% and y=100vh after reaching 50vh */}

        <animate
          attributeName="cx"
          values="70%;90%"
          keyTimes="0;1"
          dur="2s"
          begin="2s"
          repeatCount="indefinite"

        />
        <animate
          attributeName="cy"
          values="50vh;100vh"
          keyTimes="0;1"
          dur="2s"
          begin="2s"
          repeatCount="indefinite"

        />
      </circle>
 


      <line x1="30%" y1="50%" x2="30%" y2="0" stroke="#cecece" strokeWidth="2" strokeOpacity="0.1" />

      <line x1="70%" y1="50%" x2="70%" y2="0" stroke="#cecece" strokeWidth="2" strokeOpacity="0.1" />

      {/* New line starting after 50% vertical height */}
      <line
        x1="30%"
        y1="50vh"
        x2="10%"
        y2="100vh"
        stroke="#cecece"
        strokeWidth="2"
        strokeOpacity="0.1"
      />

      <line
        x1="70%"
        y1="50vh"
        x2="90%"
        y2="100vh"
        stroke="#cecece"
        strokeWidth="2"
        strokeOpacity="0.1"
      />




    </svg>
  );
};

export default PulsingMovingDotsSVG;
