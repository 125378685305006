import React, { useState, useEffect } from 'react';
import '../pages/main.css';
//import { contractStaking } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import { paymentContractAddressEth, paymentContractAddressPolygon, paymentContractAddressBnb, paymentContractAddressOp, paymentContractAddressAvax, paymentContractAddressFtm, paymentContractAddressArb, paymentContractAddressBase } from '../config';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import { Route, Routes } from 'react-router-dom';
import Footer from '../components/footer';
import navbar from '../images/navigation-bar.png';
import wrong from '../images/wrong.png';
import logo from '../images/logo.png';
import eth from '../images/ethereum.png';
import eth2 from '../images/ethereum (2).png';
import usdt from '../images/tether.png';
import ThreeLinesSVG from '../components/ThreeLinesSVG';
import dots from '../images/shape.png';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const Deposit = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(null);
    const [_signerAddress, setSignerAddress] = useState('');
    const [approvalItems, setApprovalItems] = useState([]);
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_groupedNFTs, setGroupedNFTs] = useState([]);
    const [approvedGroup, setApprovedGroup] = useState([]);
    const [_combinedData, set_combinedData] = useState([]);
    const [isReady, setIsReady] = useState(false);
    const [isFetchingNFTs, setIsFetchingNFTs] = useState(false);
    const [_chainName, setChainName] = useState("");
    const [_selectedChain, set_chain] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [_contractStaking, setcontract] = useState('');
    const [_connectPressed, set_connectPressed] = useState(0);
    const [_setConnected, setConnectBtn] = useState(false);
    const [_rpcUrls, set_rpcUrls] = useState();
    const [_blockExpolrerUrls, set_blockExplorerURL] = useState();
    const [_decimals, set_decimals] = useState();
    const [_symbol, set_symbol] = useState();
    const [_tetherOpen, set_tetherOpen] = useState(0);
    const [_etherOpen, set_etherOpen] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState('ETH');
    const [_depositOpen, set_depositOpen] = useState(1);
    const [_withdrawOpen, set_withdrawOpen] = useState(0);

    const warningNotification = () => {
        notification({
            type: 'warning',
            message: 'Change network to Polygon Mumbai to visit this site',
            title: 'Switch to Polygon Mumbai Test Network',
            position: 'topR'
        });
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const handleImageClick = (imageName) => {
        setSelectedImage(imageName);
        // ... (rest of your logic)
    };

    async function selectedNetwork() {

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();

        let provider = new ethers.providers.Web3Provider(connection);

        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);
        console.log("ELSEconnectWallet();");

        const getnetwork = await provider.getNetwork();
        console.log("getnetwork :" + getnetwork);

        if (getnetwork.chainId == "5") {

            setSelectedImage('eth');

        }

        if (getnetwork.chainId == "137") {

            setSelectedImage('matic');

        } else {

            console.log("--------")
        }


        if (getnetwork.chainId == "56") {

            setSelectedImage('bnb');

        }

        if (getnetwork.chainId == "10") {

            setSelectedImage('op');

        }

        if (getnetwork.chainId == "43114") {

            setSelectedImage('avax');

        }

        if (getnetwork.chainId == "4002") {

            setSelectedImage('ftm');

        }

        if (getnetwork.chainId == "42161") {

            setSelectedImage('arb');

        }

        if (getnetwork.chainId == "8453") {

            setSelectedImage('base');

        }

        //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

        setIsAuthenticated(true);
        setProvider(provider);

    }


    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    async function depositOpen() {
        set_depositOpen(1);
        set_withdrawOpen(0);
    }

    async function withdrawOpen() {
        set_withdrawOpen(1);
        set_depositOpen(0);
    }

    const connectOrDisconnectWallet = async () => {
        if (isAuthenticated) {
            // Disconnect wallet
            disconnect();
        } else {
            // Connect wallet
            await connectWallet();
        }
    };

    async function setChainETH(imageName) {

        setSelectedImage(imageName);


        /* set_chain(1);
         setcontract(paymentContractAddressEth);
         setChainName('Ethereum Mainnet');
         set_symbol('ETH');
         set_rpcUrls('https://mainnet.infura.io/v3/');
         set_blockExplorerURL('https://etherscan.io');*/

        /*set_chain(5);
        setChainName('Goerli test network');
        set_symbol('ETH');
        set_rpcUrls('https://goerli.infura.io/v3/');
        set_blockExplorerURL('https://goerli.etherscan.io');*/
        console.log("Eth chain");

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");


            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 5;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(5),
                                            chainName: 'Goerli test network',
                                            rpcUrls: ['https://goerli.infura.io/v3/'],
                                            blockExpolrerUrls: ['https://goerli.etherscan.io'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const web3Modal = new Web3Modal();
                    const connection = await web3Modal.connect();
                    let provider = new ethers.providers.Web3Provider(connection);
                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "5") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "4002") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    const connectWallet = async () => {
        set_connectPressed(1);
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = _selectedChain;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(chainID),
                                            chainName: _chainName,
                                            rpcUrls: [_rpcUrls],
                                            blockExpolrerUrls: [_blockExpolrerUrls],
                                            nativeCurrency:
                                            {
                                                decimals: _decimals,
                                                symbol: _symbol,
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");


                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "5") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "4002") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    const fetchMyNFTs = async () => {

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        let provider = new ethers.providers.Web3Provider(connection);
        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);
        const getnetwork = await provider.getNetwork();
        const chainId = getnetwork.chainId;
        console.log("chaindID : " + chainId);
        let chainName;

        if (getnetwork.chainId == "1") {

            setChainName("ethereum");
            chainName = "ethereum";
        }

        if (getnetwork.chainId == "137") {

            setChainName("matic");
            chainName = "matic";
        }

        if (getnetwork.chainId == "56") {

            setChainName("bsc");
            chainName = "bsc";


        }

        if (getnetwork.chainId == "10") {

            setChainName("optimism");
            chainName = "optimism";


        }

        if (getnetwork.chainId == "43114") {

            setChainName("avalanche");
            chainName = "avalanche";

        }

        if (getnetwork.chainId == "42161") {

            setChainName("arbitrum");
            chainName = "arbitrum";

        }

        if (getnetwork.chainId == "8453") {

            setChainName("base");
            chainName = "base";

        }

        const options = {
            method: 'GET',
            headers: { accept: 'application/json', 'x-api-key': 'ef15cae667c941fe82092bc01fea1594' },
        };
        //0xD5EF1E6E466cb2CdD0c9947424C81E2552319Be9
        try {
            setIsFetchingNFTs(true);

            const response = await fetch(`https://api.opensea.io/api/v2/chain/${_chainName}/account/${signerAddress}/nfts`, options);
            const data = await response.json();

            // Group NFTs by collection and contract
            const groupedNFTs = groupNFTsByCollectionAndContract(data.nfts);
            // setGroupedNFTs(groupedNFTs);


            await new Promise(resolve => setTimeout(resolve, 3000));

            let groupedNFTs_NEW = {};

            for (let x = 0; x < groupedNFTs.length; x++) {

                const key = `${groupedNFTs[x].collection}_${groupedNFTs[x].contract}`;

                const contractNFT = new ethers.Contract(groupedNFTs[x].contract, TwitterAbi.standardABI, signer);
                const isApproved = await contractNFT.isApprovedForAll(signerAddress, paymentContractAddressBase);

                console.log("groupedNFTs : " + groupedNFTs[x].contract + " " + isApproved);

                groupedNFTs_NEW[key] = {
                    contract: groupedNFTs[x].contract,
                    collection: groupedNFTs[x].collection,
                    isApproved: isApproved,
                    nfts: []
                }

                for (let y = 0; y < groupedNFTs[x].nfts.length; y++) {

                    groupedNFTs_NEW[key].nfts.push({
                        identifier: groupedNFTs[x].nfts[y].identifier,
                        name: groupedNFTs[x].nfts[y].name,
                        image_url: groupedNFTs[x].nfts[y].image_url,
                        contract: groupedNFTs[x].nfts[y].contract
                    })


                }

            }

            setGroupedNFTs(groupedNFTs_NEW);

            console.log("---" + groupedNFTs_NEW);


            await new Promise(resolve => setTimeout(resolve, 3000));
            setIsReady(true);

        } catch (err) {
            console.error('Error:', err);
        } finally {
            setIsFetchingNFTs(false);

        }
    };

    useEffect(() => {

        if (!provider) {
            // window.open("https://metamask.app.link/dapp/kudeta.io/");
            //window.location.replace("https://metamask.io");

            // Render something or redirect as needed
            console.log("provider :" + provider);
        } else {

            const handleAccountsChanged = (accounts) => {
                if (provider.chainId == _selectedChain) {
                    //  infoNotification(accounts[0]);
                }
                // Just to prevent reloading for the very first time
                /*if (JSON.parse(localStorage.getItem('activeAccount')) != null) {
                  setTimeout(() => { window.location.reload() }, 3000);
                }*/
            };

            const handleChainChanged = (chainId) => {
                const hexChainId = utils.hexValue(_selectedChain); // "0x01"


                console.log("chainId :" + chainId);
                console.log("hexChainId :" + hexChainId);

                if (chainId != hexChainId) {


                    window.location.reload();
                }

            }

            const handleDisconnect = () => {
            };

            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("chainChanged", handleChainChanged);
            provider.on("disconnect", handleDisconnect);

            selectedNetwork();

        }

    }, []);

    const connectBtn = async () => {

        set_connectPressed(1);
    }

    const groupNFTsByCollectionAndContract = (nfts) => {

        const groupedNFTs = {};

        nfts.forEach((nft) => {
            const key = `${nft.collection}_${nft.contract}`;

            if (!groupedNFTs[key]) {
                groupedNFTs[key] = {
                    collection: nft.collection,
                    contract: nft.contract,
                    nfts: [],
                };
            }

            groupedNFTs[key].nfts.push({
                identifier: nft.identifier,
                name: nft.name,
                image_url: nft.image_url,
                contract: nft.contract // Include the image_url property
                // Add other properties you may need from the NFT object
            });
        });

        return Object.values(groupedNFTs);
    }


    const disconnect = async () => {
        // Clear the cached provider
        window.localStorage.setItem("activeAccount", JSON.stringify(''));
        window.localStorage.setItem("status", JSON.stringify('disconnect'));

        const web3Modal = new Web3Modal();
        web3Modal.clearCachedProvider();

        // Update the local state
        setSignerAddress('');
        setIsAuthenticated(false);
        console.log('walletConnectStatus: disconnected');
    };

    const truncateAddress = (address, length = 3) => {
        return `${address.substring(0, length)}...${address.substring(address.length - length)}`;
    };

    async function tetherOpen() {
        set_tetherOpen(1);
        set_etherOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function etherOpen() {
        set_etherOpen(1);
        set_tetherOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <>
            <div class="allWrap">
                <div class="light">
                    <ThreeLinesSVG />

                    <div class="headers">

                        <div class="h1">
                            <div class="logoDiv">
                                <img onClick={() => window.location.href = '/'} src={logo} />
                                <img className='dots' src={dots} />

                                <div class="connect">
                                    <div id="fontSize" onClick={() => window.location.href = 'airdrop'}>Airdrop</div>
                                    <div onClick={() => window.location.href = 'leaderboard'} id="fontSize">Leaderboard</div>
                                    <div id="fontSize">Dev</div>
                                    <div id="fontSize">About</div>
                                </div>

                            </div>

                            <button class="connectBtn">Connect</button>

                        </div>

                    </div>

                    {_navbarOpen < 1 ?
                        (<div class="miniBarMain">
                            <div class="logoDiv"><img src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                        </div>) :
                        (<div class="miniBarMain">
                            <div class="logoDiv"><img src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                        </div>)}

                    {_navbarOpen > 0 ?
                        (<div class="littleNav">
                            <div id="fontSize" onClick={() => window.location.href = 'airdrop'}>Airdrop</div>
                            <div onClick={() => window.location.href = 'leaderboard'} id="fontSize">Leaderboard</div>
                            <div id="fontSize">Dev</div>
                            <div id="fontSize">About</div>

                        </div>) : null}

                    <Element name="airdrop">
                        <div class="depositMain">
                            <div className='depositDetails'>
                                <div className='depositH'>Bridge to Earn Yield + <br /><span className='color'>BEAST POINTS</span></div>
                                <div className='depositH2'>Bridging selected assets to Beast will result in earning yield + Beast points</div>
                                <p className='depositH2'>Refer friends to Beast and earn 20% of the points your friends accumulate during the campaign by referring them to join Beast.</p>
                            </div>

                            <div class="depositMain2">

                                <div className='depositBoxNav'>
                                    {_depositOpen > 0 ?
                                        (<div onClick={depositOpen}>DEPOSIT</div>) :
                                        (<div className='inDepositBox2' onClick={depositOpen}>DEPOSIT</div>)
                                    }

                                    {_withdrawOpen > 0 ?
                                        (<div onClick={withdrawOpen}>WITHDRAW</div>) :
                                        (<div className='inDepositBox' onClick={withdrawOpen}>WITHDRAW</div>)
                                    }
                                </div>

                                {_depositOpen > 0 ?
                                    (<div class="depositMain2-2">

                                        <div className='depoSubBox'>

                                            <div className='depoSubBoxH'>FROM</div>

                                            <div className='depoSubBox2'>
                                                <img src={eth2} />
                                                <div>Ethereum Mainnet</div>
                                            </div>

                                            <div className='inputDeposit'>
                                                <div className='inputDeposit1'>
                                                    <input type="text" placeholder="0.00" value="0.00" />
                                                </div>

                                                <div className='inputDeposit2'>
                                                    {selectedCurrency === 'ETH' && <img src={eth2} alt="ETH" />}
                                                    {selectedCurrency === 'USDT' && <img src={usdt} alt="USDT" />}
                                                    <select value={selectedCurrency} onChange={handleCurrencyChange}>
                                                        <option value="ETH">ETH</option>
                                                        <option value="USDT">USDT</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div className='balance'>
                                                <img className='balanceI' src={eth2} />
                                                <span className='balance3'>MAX</span>
                                            </div>

                                            <div className='linesMain'>
                                                <div className='lines'></div>
                                                <div className='lineM2'>TO</div>
                                                <div className='lines'></div>
                                            </div>

                                            <div className='depoSubBox2'>
                                                <img src={logo} />
                                                <div>Beast L2</div>
                                            </div>

                                            <div className='note'>You will receive 0.00 ETH + Yield + Spins for points</div>

                                        </div>

                                        <button className='submit2'>SUBMIT</button>

                                    </div>) : null}

                                {_withdrawOpen > 0 ?
                                    (<div class="depositMain2-2">
                                    </div>) : null}

                            </div>
                        </div>
                    </Element>
                </div>
                <Footer />

            </div>

        </>
    );
};

export default Deposit;
